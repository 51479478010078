import { Box, Container } from "@mui/material";

export default function Layout({ children }) {
  return (
    <>
      <Box sx={{ backgroundColor: "rgb(245, 246, 247)", minHeight: "400px" }}>
        <Container maxWidth="xl">{children}</Container>
      </Box>
    </>
  );
}
