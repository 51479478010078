import React from "react";
import { HashRouter } from "react-router-dom";
import { LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "moment/locale/es";

import Router from "./components/Router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

const primaryMain = "rgb(30, 187, 240)";

const theme = createTheme({
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: primaryMain,
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(0, 220, 168)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: ["Arial", "sans-serif"].join(","),
          color: primaryMain,
          textDecoration: "underline",
          textDecorationColor: "rgba(30, 187, 240, 0.4)",
          "&:hover": {
            color: primaryMain,
            textDecoration: "underline",
            textDecorationColor: primaryMain,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 10,
        },
        sizeLarge: {
          minHeight: 50,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontWeight: "bold" },
        h2: { fontWeight: "bold" },
        h3: { fontWeight: "bold" },
        h4: { fontWeight: "bold" },
        h5: { fontWeight: "bold" },
        h6: { fontWeight: "bold" },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: 40,
          borderRadius: 20,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: "0 !important",
          marginBottom: "0 !important",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          background:
            "linear-gradient(135deg, rgb(30, 187, 240) 30%, rgb(57, 223, 170) 100%)",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="es"
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <HashRouter>
            <>
              <CssBaseline />
              <Router />
            </>
          </HashRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
