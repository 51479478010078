import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Offer from "./Offer";
import { useEffect, useMemo, useState } from "react";
import toNumber from "../utils/toNumber";
import {
  AttachMoney,
  AutoAwesome,
  Close,
  InfoOutlined,
  LocalOfferOutlined,
  Place,
  PlaceOutlined,
  Sort,
  Star,
} from "@mui/icons-material";
import geoDistance from "../utils/geoDistance";
import { useTheme } from "@emotion/react";
import Layout from "./Layout";
import { useNavigate, useParams } from "react-router-dom";

function Offers() {
  const navigate = useNavigate();
  const [ventauId, setVentauId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [purchaseOffers, setPurchaseOffers] = useState([]);
  const [consignmentOffers, setConsignmentOffers] = useState([]);
  const [location, setLocation] = useState(null);
  const [purchasePageNumber, setPurchasePageNumber] = useState(0);
  const [consignmentPageNumber, setConsignmentPageNumber] = useState(0);
  const [suggestionsType, setSuggestionsTypes] = useState("national");
  const [purchaseSortBy, setPurchaseSortBy] = useState("amount");
  const [consignmentSortBy, setConsignmentSortBy] = useState("amount");
  const [compareIds, setCompareIds] = useState([]);
  const [comparing, setComparing] = useState(false);
  const [openOffer, setOpenOffer] = useState(null);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const { action } = useParams();

  useEffect(() => {
    // get id from query
    setVentauId(new URLSearchParams(window.location.search).get("ventau-id"));

    // get geolocation
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
    });

    window.scrollTo(0, 0);
  }, []);

  // fetch offers
  useEffect(() => {
    if (ventauId) {
      fetch(
        `${
          window.location.href.includes("localhost")
            ? "http://localhost"
            : "https://app.intelimotor.com"
        }/api/ventau/offers/${ventauId}?utm_campaign=${
          window.localStorage.getItem("utm_campaign") || ""
        }&utm_source=${
          window.localStorage.getItem("utm_source") || ""
        }&utm_medium=${
          window.localStorage.getItem("utm_medium") || ""
        }&utm_content=${
          window.localStorage.getItem("utm_content") || ""
        }&utm_term=${window.localStorage.getItem("utm_term") || ""}`,
        { cache: "no-store" }
      )
        .then((res) => res.json())
        .then(
          (res2) => {
            const res = res2;

            const selectedOffer = res.find((offer) => offer.isSelected);

            if (selectedOffer && action !== "change") {
              navigate(`/offer/${selectedOffer.businessUnit.id}/view`);
              return;
            }

            if (res.length === 0) {
              setLoading(false);
              return;
            }

            for (const { type, setOffers, amountProperty } of [
              {
                type: "purchase",
                setOffers: setPurchaseOffers,
                amountProperty: "amount",
              },
              {
                type: "consignment",
                setOffers: setConsignmentOffers,
                amountProperty: "consignmentFee",
              },
            ]) {
              const offers = res.filter((offer) => offer.type === type);

              const sortedByAmount = [...offers].sort(
                (a, b) => a[amountProperty] - b[amountProperty]
              );
              const lowestAmount = offers.length
                ? sortedByAmount[0][amountProperty]
                : 0;
              const highestAmount = offers.length
                ? sortedByAmount[sortedByAmount.length - 1][amountProperty]
                : 0;

              setOffers(
                sortedByAmount.map((offer) => {
                  let amountScore =
                    (offer[amountProperty] - lowestAmount) /
                    (highestAmount - lowestAmount);

                  if (type === "consignment") {
                    amountScore = 1 - amountScore;
                  }

                  const overallScore =
                    offer.businessUnit.rating * 10 +
                    75 * amountScore +
                    25 * offer.businessUnit.isSuperBuyer;

                  return {
                    ...offer,
                    amountScore,
                    overallScore,
                  };
                })
              );
            }

            setLoading(false);
          },
          () => {}
        );
    }
  }, [ventauId, navigate]);

  // calculate offer distances
  useEffect(() => {
    for (const { offers, setOffers } of [
      {
        offers: purchaseOffers,
        setOffers: setPurchaseOffers,
      },
      {
        offers: consignmentOffers,
        setOffers: setConsignmentOffers,
      },
    ]) {
      if (offers.length > 0 && location && !offers[0].distance) {
        const offersWithDistance = offers.map((offer) => ({
          ...offer,
          distance: geoDistance(
            {
              lat: Number.parseFloat(offer.businessUnit.lat, 10),
              lon: Number.parseFloat(offer.businessUnit.lon, 10),
            },
            location
          ),
        }));

        const sortedByDistance = offersWithDistance.sort(
          (a, b) => a.distance - b.distance
        );
        const lowestDistance = sortedByDistance[0].distance;
        const highestDistance =
          sortedByDistance[sortedByDistance.length - 1].distance;

        setOffers(
          offersWithDistance.map((offer) => {
            const distanceScore =
              1 -
              (offer.distance - lowestDistance) /
                (highestDistance - lowestDistance);

            const overallScore =
              offer.businessUnit.rating * 10 +
              75 * offer.amountScore +
              50 * distanceScore +
              25 * offer.businessUnit.isSuperBuyer;

            return { ...offer, distanceScore, overallScore };
          })
        );
      }
    }
  }, [location, purchaseOffers, consignmentOffers]);

  // calculate suggestions
  const { purchaseSuggestions, consignmentSuggestions } = useMemo(() => {
    const suggestions = {
      purchaseSuggestions: [],
      consignmentSuggestions: [],
    };

    for (const { type, offers } of [
      {
        type: "purchase",
        offers: purchaseOffers,
      },
      {
        type: "consignment",
        offers: consignmentOffers,
      },
    ]) {
      const sugestionTypeOffers = offers.filter(
        (offer) => suggestionsType === "national" || offer.distance < 150
      );

      const bestOverall = [...sugestionTypeOffers].sort(
        (a, b) => b.overallScore - a.overallScore
      )[0];

      const bestByRating = [...sugestionTypeOffers].sort(
        (a, b) => b.businessUnit.rating - a.businessUnit.rating
      )[0];

      const bestByAmount = [...sugestionTypeOffers].sort(
        (a, b) => b.amount - a.amount
      )[0];

      const bestByDistance = location
        ? [...sugestionTypeOffers].sort((a, b) => a.distance - b.distance)[0]
        : null;

      suggestions[`${type}Suggestions`] = [
        {
          offer: bestOverall,
          feature: "La mejor opción",
          featureIcon: <AutoAwesome />,
        },
        {
          offer: bestByRating,
          feature: "La mejor calificada",
          featureIcon: <Star />,
        },
        {
          offer: bestByAmount,
          feature:
            type === "consignment" ? "La de menor comisión" : "La mejor pagada",
          featureIcon: <AttachMoney />,
        },
        {
          offer: bestByDistance,
          feature: "La más cercana a ti",
          featureIcon: <Place />,
        },
      ]
        .filter((suggestion) => suggestion.offer)
        .filter(
          (suggestion, idx, arr) =>
            arr.findIndex(
              (item) =>
                item.offer.businessUnit.id === suggestion.offer.businessUnit.id
            ) === idx
        );
    }

    return suggestions;
  }, [purchaseOffers, consignmentOffers, suggestionsType, location]);

  // sort offers
  const { sortedPurchaseOffers, sortedConsignmentOffers } = useMemo(() => {
    const sortedOffers = {
      sortedPurchaseOffers: [],
      sortedConsignmentOffers: [],
    };

    for (const { offers, sortBy, result } of [
      {
        type: "purchase",
        offers: purchaseOffers,
        sortBy: purchaseSortBy,
        result: "sortedPurchaseOffers",
      },
      {
        type: "consignment",
        offers: consignmentOffers,
        sortBy: consignmentSortBy,
        result: "sortedConsignmentOffers",
      },
    ]) {
      switch (sortBy) {
        case "amount":
          sortedOffers[result] = [...offers].sort(
            (a, b) => b.amountScore - a.amountScore
          );
          break;
        case "rating":
          sortedOffers[result] = [...offers].sort(
            (a, b) => b.businessUnit.rating - a.businessUnit.rating
          );
          break;
        case "distance":
          sortedOffers[result] = [...offers].sort(
            (a, b) => a.distance - b.distance
          );
          break;
        case "az":
          sortedOffers[result] = [...offers].sort((a, b) =>
            a.businessUnit.name.localeCompare(b.businessUnit.name)
          );
          break;
        case "za":
          sortedOffers[result] = [...offers].sort((a, b) =>
            b.businessUnit.name.localeCompare(a.businessUnit.name)
          );
          break;
        default:
          sortedOffers[result] = offers;
      }
    }

    return sortedOffers;
  }, [purchaseOffers, purchaseSortBy, consignmentOffers, consignmentSortBy]);

  // find comparing offers by id
  const comparingOffers = useMemo(
    () =>
      [...purchaseOffers, ...consignmentOffers].filter((offer) =>
        compareIds.includes(offer.businessUnit.id)
      ),
    [purchaseOffers, consignmentOffers, compareIds]
  );

  const hideConsignments = useMemo(() => {
    if (
      [...purchaseOffers, ...consignmentOffers].filter(
        (offer) => offer.everSelected
      ).length >= 2
    ) {
      return false;
    }

    if (
      purchaseOffers.filter((offer) => {
        // is it good?
        if (offer.discount >= 30) return false;
        if (offer.distance && offer.distance > 150) return false;
        return true;
      }).length > 3 &&
      purchaseOffers.length >= 5
    ) {
      return true;
    }

    return false;
  }, [purchaseOffers, consignmentOffers]);

  if (loading) {
    return (
      <Layout>
        <Box sx={{ textAlign: "center", py: 14 }}>
          <CircularProgress size={100} sx={{ mb: 2 }} />
          <Typography variant="subtitle2">
            Estamos calculando tus ofertas
          </Typography>
        </Box>
      </Layout>
    );
  }

  if (purchaseOffers.length === 0 && consignmentOffers.length === 0) {
    return (
      <Layout>
        <Stack spacing={8} sx={{ py: 8 }}>
          <Box>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              La mejor oferta está a la vuelta de la esquina…
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Por el momento, ninguno de las Agencias de la red de Ventau ha
              mostrado interés por adquirir un auto como el tuyo. Pero, ¡no te
              desanimes! Todos los días se siguen sumando Agencias afiliadas a
              Ventau y pudiera haber interés por tu auto tan pronto como mañana.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              ¿Por qué no hay ofertas por mi auto?
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Las Agencias de la red de Ventau eligen si quieren ofertar por un
              auto basado en la marca, modelo, antigüedad o kilometraje. En este
              momento no hay Agencias en la red de Ventau que hayan configurado
              ofertar por un auto como el tuyo.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              ¿De qué otra manera puedo vender mi auto?
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Nuestro equipo está dedicado a asistirte en el proceso de venta de
              tu vehículo. Un asesor de Ventau te contactará pronto para
              discutir estrategias alternativas y maximizar las oportunidades de
              venta de tu auto.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Preguntas Frecuentes
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Si tienes alguna pregunta o inquietud no dudes en visitar nuestra
              sección de Preguntas Frecuentes donde encontrarás información muy
              útil. ¿Aún con dudas? No te preocupes, recuerda que puedes
              comunicarte con nosotros en cualquier momento en nuestra línea de
              soporte: (+52 1 55 7005 8768).
            </Typography>
          </Box>
        </Stack>
      </Layout>
    );
  }

  const suggestions = purchaseSuggestions.length
    ? purchaseSuggestions
    : consignmentSuggestions;

  const offersCount = purchaseOffers.length || consignmentOffers.length;

  return (
    <Layout>
      <Stack spacing={4} sx={{ py: 3 }}>
        <Box>
          {purchaseOffers.length > 0 && (
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Compara y elige la{" "}
              <span className="gradient gradient--text">mejor opción</span>
            </Typography>
          )}

          {purchaseOffers.length === 0 && (
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              ¡Una opción{" "}
              <span className="gradient gradient--text">especial para ti</span>!
            </Typography>
          )}
        </Box>

        {purchaseOffers.length === 0 && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{ textAlign: "center", maxWidth: 600 }}
              color="textSecondary"
            >
              Si bien en este momento no hay ofertas de compra para autos como
              el tuyo, te sugerimos considerar las alternativas de{" "}
              <b>consignación</b>.
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2} sx={{ maxWidth: 800 }}>
            <Grid item xs={12} md={location ? 6 : 12}>
              <Paper variant="outlined">
                <List>
                  <ListItem sx={{ minHeight: "56px" }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <LocalOfferOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        offersCount === 1
                          ? "¡Tenemos una oferta para ti!"
                          : `¡${toNumber(
                              offersCount
                            )} Agencias tienen una oferta para ti!`
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            {location && (
              <Grid item xs={12} md={location ? 6 : 12}>
                <Paper variant="outlined">
                  <List>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <PlaceOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ mr: 1 }} color="GrayText">
                              Sugerencias:
                            </Typography>
                            <FormControl
                              variant="standard"
                              fullWidth
                              margin="none"
                              sx={{
                                "& :before": {
                                  display: "none",
                                },
                              }}
                            >
                              <Select
                                value={suggestionsType}
                                onChange={(e) =>
                                  setSuggestionsTypes(e.target.value)
                                }
                              >
                                <MenuItem value="local">Cerca de ti</MenuItem>
                                <MenuItem value="national">Nacionales</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        }
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box sx={{ pt: 4 }}>
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            {suggestions.map((suggestion) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={
                  suggestions.length <= 2 ? 5 : suggestions.length === 3 ? 4 : 3
                }
              >
                <Offer
                  variant="featured"
                  feature={suggestion.feature}
                  featureIcon={suggestion.featureIcon}
                  data={suggestion.offer}
                  comparing={compareIds.includes(
                    suggestion.offer.businessUnit.id
                  )}
                  toggleComparing={() =>
                    setCompareIds(
                      compareIds.includes(suggestion.offer.businessUnit.id)
                        ? compareIds.filter(
                            (id) => id !== suggestion.offer.businessUnit.id
                          )
                        : [...compareIds, suggestion.offer.businessUnit.id]
                    )
                  }
                  cannotCompare={compareIds.length === 3}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>

      {[
        {
          type: "purchase",
          offers: sortedPurchaseOffers,
          suggestions: purchaseSuggestions,
          pageNumber: purchasePageNumber,
          setPageNumber: setPurchasePageNumber,
          sortBy: purchaseSortBy,
          setSortBy: setPurchaseSortBy,
          pageSize: 10,
        },
        ...(hideConsignments
          ? []
          : [
              {
                type: "consignment",
                offers: sortedConsignmentOffers,
                suggestions:
                  purchaseSuggestions.length > 0 ? [] : consignmentSuggestions,
                pageNumber: consignmentPageNumber,
                setPageNumber: setConsignmentPageNumber,
                sortBy: consignmentSortBy,
                setSortBy: setConsignmentSortBy,
                pageSize: 5,
              },
            ]),
      ].map(
        ({
          type,
          offers,
          suggestions,
          pageNumber,
          setPageNumber,
          sortBy,
          setSortBy,
          pageSize,
        }) => {
          if (offers.length <= suggestions.length) return null;

          return (
            <>
              {type === "consignment" &&
                purchaseOffers.length > purchaseSuggestions.length && (
                  <Box sx={{ py: 8 }}>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", mb: 4 }}
                    >
                      ¿No encontraste lo que buscabas?
                      <br />
                      <span className="gradient gradient--text">
                        Tenemos más opciones para ti.
                      </span>
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        sx={{ textAlign: "center", maxWidth: 600 }}
                        color="textSecondary"
                      >
                        Parece que las ofertas de compra por tu auto no terminan
                        de convencerte. ¡No te preocupes! Te sugerimos
                        considerar alternativas de <b>consignación</b>.
                      </Typography>
                    </Box>
                  </Box>
                )}

              <Box sx={{ py: 3 }}>
                {type === "purchase" && (
                  <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                    Compara{" "}
                    <Box component="span" sx={{ color: "GrayText" }}>
                      más opciones
                    </Box>
                  </Typography>
                )}

                {type === "consignment" &&
                  (purchaseOffers.length === 0 ||
                    purchaseOffers.length > purchaseSuggestions.length) && (
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", maxWidth: 600, mb: 2 }}
                    >
                      Compara{" "}
                      <Box component="span" sx={{ color: "GrayText" }}>
                        más opciones de consignación
                      </Box>
                      <Tooltip title="La consignación te ofrece flexibilidad para vender tu auto. Dependiendo de cada Agencia, se puede exhibir en su espacio físico, en plataformas digitales o ambas mientras mantienes la propiedad del auto. Con esto se logra ampliar el alcance de tu auto a más compradores potenciales. Las consignaciones cuentan con una comisión por el servicio cuando se concreta la venta.">
                        <InfoOutlined color="action" sx={{ mb: 1, ml: 0.5 }} />
                      </Tooltip>
                    </Typography>
                  )}

                {type === "consignment" &&
                  purchaseOffers.length > 0 &&
                  purchaseOffers.length <= purchaseSuggestions.length && (
                    <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                      ¿No encontraste lo que buscabas?
                      <br />
                      <Box component="span" sx={{ color: "GrayText" }}>
                        Tenemos más opciones de consignación
                      </Box>
                      <Tooltip title="La consignación te ofrece flexibilidad para vender tu auto. Dependiendo de cada Agencia, se puede exhibir en su espacio físico, en plataformas digitales o ambas mientras mantienes la propiedad del auto. Con esto se logra ampliar el alcance de tu auto a más compradores potenciales. Las consignaciones cuentan con una comisión por el servicio cuando se concreta la venta.">
                        <InfoOutlined color="action" sx={{ mb: 1, ml: 0.5 }} />
                      </Tooltip>
                    </Typography>
                  )}

                <Typography color="GrayText">
                  Elige hasta 3 opciones a la vez.
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Paper variant="outlined">
                  <List>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <Sort />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{ mr: 1, whiteSpace: "nowrap" }}
                              color="GrayText"
                            >
                              Ordenar por:
                            </Typography>
                            <FormControl
                              variant="standard"
                              fullWidth
                              margin="none"
                              sx={{
                                "& :before": {
                                  display: "none",
                                },
                              }}
                            >
                              <Select
                                value={sortBy}
                                onChange={(e) => {
                                  setSortBy(e.target.value);
                                  setPageNumber(0);
                                }}
                              >
                                <MenuItem value="amount">
                                  {type === "consignment"
                                    ? "Menor comisión"
                                    : "Mejor pagadas"}
                                </MenuItem>
                                <MenuItem value="rating">
                                  Mejor calificadas
                                </MenuItem>
                                {location && (
                                  <MenuItem value="distance">
                                    Más cerca de ti
                                  </MenuItem>
                                )}
                                <MenuItem value="az">
                                  Alfabético (A - Z)
                                </MenuItem>
                                <MenuItem value="za">
                                  Alfabético (Z - A)
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        }
                      />
                    </ListItem>
                  </List>
                </Paper>

                <Hidden smDown>
                  <Box>
                    <TablePagination
                      component="div"
                      count={offers.length}
                      page={pageNumber}
                      onPageChange={(e, newPageNumber) =>
                        setPageNumber(newPageNumber)
                      }
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[]}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count} opciones`
                      }
                    />
                  </Box>
                </Hidden>
              </Box>

              <Stack spacing={2} sx={{ pb: 4 }}>
                {offers
                  .slice(
                    pageNumber * pageSize,
                    pageNumber * pageSize + pageSize
                  )
                  .map((offer) => (
                    <Offer
                      key={offer.businessUnit.id}
                      variant={isSm ? "mobile-list" : "list"}
                      data={offer}
                      comparing={compareIds.includes(offer.businessUnit.id)}
                      toggleComparing={() =>
                        setCompareIds(
                          compareIds.includes(offer.businessUnit.id)
                            ? compareIds.filter(
                                (id) => id !== offer.businessUnit.id
                              )
                            : [...compareIds, offer.businessUnit.id]
                        )
                      }
                      cannotCompare={compareIds.length === 3}
                      handleOpen={() => setOpenOffer(offer)}
                    />
                  ))}
              </Stack>
              <Box sx={{ pb: 4, display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={Math.ceil(offers.length / pageSize)}
                  page={pageNumber + 1}
                  onChange={(e, newPageNumber) =>
                    setPageNumber(newPageNumber - 1)
                  }
                  variant="outlined"
                  color="primary"
                />
              </Box>
              <Box
                sx={{
                  pb: 6,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  Los montos aquí detallados, así como las ofertas y comisiones
                  finales están sujetas a inspección de la unidad y a la
                  celebración de un acuerdo definitivo entre el dueño del
                  vehículo y la Agencia interesada en adquirirlo.
                </Typography>
              </Box>
            </>
          );
        }
      )}

      <Dialog open={Boolean(openOffer)} onClose={() => setOpenOffer(null)}>
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpenOffer(null)}
          >
            <Close />
          </IconButton>
          {openOffer && (
            <Offer
              noBorder
              variant="featured"
              data={openOffer}
              comparing={compareIds.includes(openOffer.businessUnit.id)}
              toggleComparing={() =>
                setCompareIds(
                  compareIds.includes(openOffer.businessUnit.id)
                    ? compareIds.filter(
                        (id) => id !== openOffer.businessUnit.id
                      )
                    : [...compareIds, openOffer.businessUnit.id]
                )
              }
              cannotCompare={compareIds.length === 3}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xl"
        open={comparing}
        onClose={() => setComparing(false)}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => setComparing(false)}
          >
            <Close />
          </IconButton>
          <Grid container>
            {comparingOffers.map((offer, idx) => (
              <>
                {idx > 0 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: "-1px", zIndex: 1 }}
                  />
                )}
                <Grid item xs={12} md={compareIds.length === 2 ? 6 : 4}>
                  <Offer noBorder variant="featured" data={offer} hideCompare />
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      {compareIds.length > 0 && (
        <AppBar
          color="inherit"
          position="fixed"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {[0, 1, 2].map((idx) => {
              const offer = comparingOffers[idx];

              const initials = offer
                ? offer.businessUnit.name
                    .split(" ")
                    .filter(
                      (item, idx, arr) =>
                        idx === 0 || (arr.length === 2 && idx === 1)
                    )
                    .map((item) => item[0])
                    .join("")
                    .toUpperCase()
                : null;

              return offer ? (
                <Avatar
                  sx={{
                    mr: 1,
                    width: 48,
                    height: 48,
                    boxShadow: 1,
                  }}
                  variant="rounded"
                  src={offer.businessUnit.logo}
                >
                  {initials}
                </Avatar>
              ) : (
                <Avatar sx={{ mr: 1, width: 48, height: 48 }} variant="rounded">
                  ?
                </Avatar>
              );
            })}
            <Button
              variant="contained"
              className={compareIds.length > 1 ? "gradient" : ""}
              onClick={() => setComparing(true)}
              disabled={compareIds.length < 2}
              size="large"
              sx={{ ml: 1 }}
            >
              Comparar ofertas
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </Layout>
  );
}

export default Offers;
