import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AttachMoney,
  BoltOutlined,
  CarRepair,
  CheckBox,
  CheckBoxOutlineBlank,
  CompareArrows,
  EmojiTransportation,
  InfoOutlined,
  MoneyOff,
  Payments,
  Place,
  Sell,
  Speed,
} from "@mui/icons-material";
import toCurrency from "../utils/toCurrency";
import toNumber from "../utils/toNumber";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function translatePaymentType(paymentType) {
  switch (paymentType) {
    case "cash":
      return "efectivo";
    case "transfer":
      return "transferencia";
    case "check":
      return "cheque";
    default:
      return "";
  }
}

function translateInspectionType(inspectionType) {
  switch (inspectionType) {
    case "business":
      return "en sucursal";
    case "home":
      return "a domicilio";
    case "remote":
      return "virtuales";
    default:
      return "";
  }
}

function ListedOffer({
  variant = "list",
  feature,
  featureIcon,
  data: {
    businessUnit: {
      id,
      name,
      logo,
      rating,
      city,
      state,
      isSuperBuyer,
      exchanges,
      notfullypaid,
      timeToPayment,
      timeToPaymentUnit,
      paymentTypes,
      inspectionTypes,
      noMaxKms,
      maxKms,
      requiresPhysicalConsignments,
      consignmentsNoMaxKms,
      consignmentsMaxKms,
    },
    type,
    amount,
    consignmentFeeType,
    consignmentFee,
    distance,
  },
  comparing,
  toggleComparing,
  cannotCompare,
  handleOpen,
  noBorder,
  hideCompare,
  noActions,
}) {
  const theme = useTheme();
  const isXsAndDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isLgAndUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isXlAndUp = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();

  const initials = name
    .split(" ")
    .filter((item, idx, arr) => idx === 0 || (arr.length === 2 && idx === 1))
    .map((item) => item[0])
    .join("")
    .toUpperCase();

  const formattedDistance = distance
    ? `${toNumber(Math.round(distance))} kms`
    : null;

  let timeToPaymentPredicate = null;
  const daysToPayment = moment()
    .add(timeToPayment, timeToPaymentUnit)
    .diff(moment(), "days");
  if (daysToPayment === 0) {
    timeToPaymentPredicate = "hoy mismo";
  } else if (daysToPayment === 1) {
    timeToPaymentPredicate = "mañana mismo";
  } else if (daysToPayment === 2) {
    timeToPaymentPredicate = "en menos de una semana";
  } else if (daysToPayment <= 4) {
    timeToPaymentPredicate = "en una semana";
  }

  return (
    <Card
      sx={{
        ...(variant === "list"
          ? { display: "flex", flexDirection: "row" }
          : {
              height: noActions ? "auto" : "100%",
              display: "flex",
              flexDirection: "column",
            }),
        ...(noBorder ? { boxShadow: "none", border: "none" } : {}),
        position: "relative",
        overflow: "visible",
      }}
      variant="outlined"
    >
      {isSuperBuyer && variant === "featured" && !noBorder && (
        <Tooltip title="Supercomprador">
          <Box
            component="img"
            src="https://intelimotor.s3.amazonaws.com/b64c43b1-cdfa-4f40-abc2-c10071ed13da.png"
            sx={{
              width: 60,
              height: 60,
              position: "absolute",
              top: -16,
              right: -16,
            }}
          />
        </Tooltip>
      )}
      <CardHeader
        avatar={
          <Avatar
            src={logo}
            variant="rounded"
            className={variant === "featured" && !logo ? "gradient" : ""}
            sx={{ width: 60, height: 60 }}
          >
            {featureIcon || initials}
          </Avatar>
        }
        title={
          <Box>
            {variant === "featured" && (
              <Rating value={rating} readOnly sx={{ fontSize: 14 }} />
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={variant === "featured" ? "h6" : "subtitle2"}
                sx={{ mt: -1 }}
              >
                {name}
              </Typography>
              {isSuperBuyer && (variant !== "featured" || noBorder) && (
                <Tooltip title="Supercomprador">
                  <Box
                    component="img"
                    src="https://intelimotor.s3.amazonaws.com/b64c43b1-cdfa-4f40-abc2-c10071ed13da.png"
                    sx={{
                      width: 24,
                      height: 24,
                      ml: 1,
                      mt: -1,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        }
        subheader={
          variant === "list" ? (
            <Box sx={{ display: "flex", alignItems: "center", lineHeight: 1 }}>
              <Rating
                max={1}
                value={1}
                readOnly
                sx={{ fontSize: "16px", marginRight: 0.5 }}
              />
              <span>{rating >= 0 ? rating.toFixed(1) : "--"}</span>
            </Box>
          ) : (
            feature
          )
        }
        sx={{ flex: 1 }}
      />
      <CardContent
        sx={
          variant === "list"
            ? { flex: 2, display: "flex", alignItems: "center" }
            : {
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        <Box sx={{ flex: type === "consignment" ? 1.5 : 1 }}>
          {variant !== "list" && (
            <Typography sx={{ fontWeight: "bold" }}>
              {type === "consignment" &&
                consignmentFeeType === "percentage" &&
                "Comisión del"}

              {type === "consignment" &&
                consignmentFeeType === "amount" &&
                "Comisión de"}

              {type === "purchase" && "Pago de"}
            </Typography>
          )}
          <Box
            sx={
              variant === "featured"
                ? { display: "flex", justifyContent: "center" }
                : {}
            }
          >
            <Typography
              variant={
                variant === "featured"
                  ? "h2"
                  : type === "consignment"
                  ? "h6"
                  : "h5"
              }
              sx={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
                ...(variant === "featured" && type === "consignment"
                  ? { paddingLeft: "18px" }
                  : {}),
              }}
            >
              {type === "purchase" && toCurrency(amount)}

              {variant === "list" &&
                type === "consignment" &&
                consignmentFeeType === "percentage" &&
                "Comisión del "}

              {variant === "list" &&
                type === "consignment" &&
                consignmentFeeType === "amount" &&
                "Comisión de "}

              {type === "consignment" &&
                consignmentFeeType === "amount" &&
                toCurrency(consignmentFee)}

              {type === "consignment" &&
                consignmentFeeType === "percentage" &&
                `${consignmentFee}%`}
            </Typography>

            {variant === "featured" && type === "consignment" && (
              <Tooltip title="Sobre el precio final de venta, sujeto a inspección y negociación con tu Asesor en la Agencia.">
                <InfoOutlined color="action" sx={{ fontSize: 14, ml: 0.5 }} />
              </Tooltip>
            )}
          </Box>

          {variant !== "list" && (
            <Typography>
              {type === "purchase" &&
                `${["por tu auto", timeToPaymentPredicate]
                  .filter(Boolean)
                  .join(", ")}.`}

              {type === "consignment" && "por vender tu auto por ti."}
            </Typography>
          )}

          {variant === "list" && type === "consignment" && (
            <Typography variant="caption" sx={{ fontWeight: "bold" }}>
              Sobre la venta final de tu auto
            </Typography>
          )}
        </Box>

        {variant === "list" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 2,
            }}
          >
            <Place color="error" sx={{ marginRight: 0.5 }} />
            <Typography variant="body2">
              {[[city, state].join(", "), formattedDistance]
                .filter(Boolean)
                .join(" · ")}
            </Typography>
          </Box>
        )}
      </CardContent>

      {variant === "featured" && (
        <CardContent sx={{ flexGrow: 1 }}>
          <List>
            {variant !== "list" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <Place color="error" />
                </ListItemIcon>
                <ListItemText
                  primary={[[city, state].join(", "), formattedDistance]
                    .filter(Boolean)
                    .join(" · ")}
                />
              </ListItem>
            )}
            {type === "purchase" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <BoltOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {`¡Recibe tu pago en ${timeToPayment} ${
                        timeToPaymentUnit === "days" ? "día" : "hora"
                      }${timeToPayment !== "1" ? "s" : ""}!`}

                      <Tooltip title="A partir de la inspección física y validación documental de tu vehículo.">
                        <InfoOutlined
                          fontSize="small"
                          color="action"
                          sx={{
                            ml: 1,
                            position: "relative",
                            top: 2,
                            fontSize: 16,
                          }}
                        />
                      </Tooltip>
                    </>
                  }
                />
              </ListItem>
            )}
            {type === "consignment" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <EmojiTransportation />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box>
                      {requiresPhysicalConsignments
                        ? "Recibe tu auto en la Agencia"
                        : "No es necesario dejar tu auto"}

                      <Tooltip
                        title={
                          requiresPhysicalConsignments
                            ? "La Agencia recibirá tu auto para exposición física en sus instalaciones. Tu vehículo será publicado de igual manera en las diferentes plataformas digitales en las que participa la Agencia."
                            : "Tu vehículo será publicado y exhibido en las diferentes plataformas digitales en las que participa la Agencia, por lo cual no es necesario que dejes tu auto físicamente. Considera que deberás llevar tu auto a la Agencia para que lo pueda ver el comprador interesado."
                        }
                      >
                        <InfoOutlined
                          color="action"
                          sx={{ fontSize: 14, ml: 0.5 }}
                        />
                      </Tooltip>
                    </Box>
                  }
                />
              </ListItem>
            )}
            <ListItem sx={{ pb: 0 }} disableGutters>
              <ListItemIcon>
                <CarRepair />
              </ListItemIcon>
              <ListItemText
                primary={`Inspecciones ${[
                  inspectionTypes
                    .slice(0, inspectionTypes.length - 1)
                    .map((item) => translateInspectionType(item))
                    .join(", "),
                  translateInspectionType(
                    inspectionTypes[inspectionTypes.length - 1]
                  ),
                ]
                  .filter(Boolean)
                  .join(" o ")}`}
              />
            </ListItem>
            {type === "purchase" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <Payments />
                </ListItemIcon>
                <ListItemText
                  primary={`Pago con ${[
                    paymentTypes
                      .slice(0, paymentTypes.length - 1)
                      .map((item) => translatePaymentType(item))
                      .join(", "),
                    translatePaymentType(paymentTypes[paymentTypes.length - 1]),
                  ]
                    .filter(Boolean)
                    .join(" o ")}`}
                />
              </ListItem>
            )}
            {type === "purchase" && exchanges && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <CompareArrows />
                </ListItemIcon>
                <ListItemText primary="Toma autos a cuenta" />
              </ListItem>
            )}

            {type === "purchase" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  {notfullypaid ? <AttachMoney /> : <MoneyOff color="error" />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    notfullypaid
                      ? "Acepta autos con crédito activo"
                      : "No acepta autos con crédito activo"
                  }
                />
              </ListItem>
            )}

            <ListItem sx={{ pb: 0 }} disableGutters>
              <ListItemIcon>
                <Speed />
              </ListItemIcon>
              <ListItemText
                primary={
                  (type === "consignment" ? consignmentsNoMaxKms : noMaxKms)
                    ? "Acepta autos con cualquier kilometraje"
                    : `Kilometraje aceptado hasta ${toNumber(
                        type === "consignment" ? consignmentsMaxKms : maxKms
                      )}km`
                }
              />
            </ListItem>

            {type === "consignment" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <Sell />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box>
                      {consignmentFeeType === "percentage"
                        ? `Comisión fija del ${consignmentFee}%`
                        : `Comisión fija de ${toCurrency(consignmentFee)} MXN`}

                      <Tooltip
                        title={
                          consignmentFeeType === "percentage"
                            ? "Este porcentaje de comisión equivale al porcentaje que la agencia asignará a sus servicios de Consignación sobre una venta exitosa de tu vehículo. Tu auto se publicará al monto que tu elijas, asesorado por la Agencia de tu preferencia. Pregunta a tu asesor sobre gastos de acondicionamiento y exposición en tu cita de inspección."
                            : "Este es el monto fijo que la agencia recibirá por sus servicios de Consignación sobre la venta exitosa de tu vehículo. Tu auto se publicará al monto que tu elijas, asesorado por la Agencia de tu preferencia. Pregunta a tu asesor sobre gastos de acondicionamiento y exposición en tu cita de inspección."
                        }
                      >
                        <InfoOutlined
                          color="action"
                          sx={{ fontSize: 14, ml: 0.5 }}
                        />
                      </Tooltip>
                    </Box>
                  }
                />
              </ListItem>
            )}

            {type === "consignment" && (
              <ListItem sx={{ pb: 0 }} disableGutters>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary="Comisión sobre monto final de la venta" />
              </ListItem>
            )}
          </List>
        </CardContent>
      )}

      {!noActions && (
        <CardActions
          sx={
            variant === "mobile-list" ||
            (variant === "featured" && isLgAndUp && !isXlAndUp) ||
            (variant === "featured" && isXsAndDown)
              ? { flexDirection: "column" }
              : {}
          }
        >
          {handleOpen && (
            <Button
              variant="outlined"
              onClick={handleOpen}
              fullWidth={variant === "mobile-list"}
              sx={
                variant === "mobile-list" ? { mx: "0 !important", mb: 0.5 } : {}
              }
            >
              Ver oferta
            </Button>
          )}
          {!hideCompare && (
            <Button
              variant={comparing ? "contained" : "outlined"}
              onClick={toggleComparing}
              disabled={!comparing && cannotCompare}
              fullWidth={
                variant === "mobile-list" ||
                (variant === "featured" && isLgAndUp && !isXlAndUp) ||
                (variant === "featured" && isXsAndDown)
              }
              sx={
                variant === "mobile-list" ||
                (variant === "featured" && isLgAndUp && !isXlAndUp) ||
                (variant === "featured" && isXsAndDown)
                  ? { mx: "0 !important", mb: 0.5 }
                  : {}
              }
              size={variant === "featured" ? "large" : "medium"}
            >
              {comparing ? (
                <CheckBox sx={{ mr: 0.5 }} />
              ) : (
                <CheckBoxOutlineBlank sx={{ mr: 0.5 }} />
              )}
              <span>Comparar</span>
            </Button>
          )}
          <Button
            variant="contained"
            className="gradient"
            fullWidth={
              variant === "mobile-list" ||
              (variant === "featured" && isLgAndUp && !isXlAndUp) ||
              (variant === "featured" && isXsAndDown)
            }
            sx={{
              ...(variant === "mobile-list" ||
              (variant === "featured" && isLgAndUp && !isXlAndUp) ||
              (variant === "featured" && isXsAndDown)
                ? { mx: "0 !important", mb: 0.5 }
                : {}),
              ...(variant === "featured" ? { flex: 1 } : {}),
            }}
            onClick={() => navigate(`/offer/${id}/schedule`)}
            size={variant === "featured" ? "large" : "medium"}
          >
            Agendar inspección
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default ListedOffer;
