import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Redirector from "./Redirector";
import Offers from "./Offers";
import Inspection from "./Inspection";

function Router() {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (!query.get("ventau-id")) {
      window.location.href = "https://ventau.com/";
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Redirector />} />
      <Route exact path="/offers/:action?" element={<Offers />} />
      <Route exact path="/offer/:businessUnitId/:action" element={<Inspection />} />
    </Routes>
  );
}

export default Router;
