import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";

import Layout from "./Layout";
import Offer from "./Offer";
import {
  ChevronLeft,
  DateRange,
  Event,
  LocationOnOutlined,
  ScheduleSend,
  Schedule as ScheduleIcon,
  CarCrashOutlined,
  CalendarToday,
  InsertInvitation,
  AccessTime,
  PlaceOutlined,
  CompareArrows,
} from "@mui/icons-material";
import toNumber from "../utils/toNumber";

function Inspection() {
  const navigate = useNavigate();
  const { businessUnitId, action } = useParams();
  const [ventauId, setVentauId] = useState(null);
  const [offer, setOffer] = useState(null);
  const [date, setDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState(null);
  const [sending, setSending] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [changeOfferOpen, setChangeOfferOpen] = useState(false);
  const [confirmedChange, setConfirmedChange] = useState(false);

  useEffect(() => {
    // get id from query
    setVentauId(new URLSearchParams(window.location.search).get("ventau-id"));
    window.scrollTo(0, 0);
  }, []);

  // fetch offer
  useEffect(() => {
    if (ventauId && businessUnitId && !offer) {
      fetch(
        `${
          window.location.href.includes("localhost")
            ? "http://localhost"
            : "https://app.intelimotor.com"
        }/api/ventau/offers/${ventauId}/${businessUnitId}`,
        { cache: "no-store" }
      )
        .then((res) => res.json())
        .then(
          (res) => setOffer(res),
          () => {}
        );
    }
  }, [ventauId, businessUnitId, offer]);

  useEffect(() => {
    setAvailableTimeSlots(null);
    if (date) {
      fetch(
        `${
          window.location.href.includes("localhost")
            ? "http://localhost"
            : "https://app.intelimotor.com"
        }/api/ventau/offers/${ventauId}/${businessUnitId}/availableTimeSlots?date=${date}`,
        { cache: "no-store" }
      )
        .then((res) => res.json())
        .then(
          (res) => setAvailableTimeSlots(res),
          () => {}
        );
    }
  }, [date, businessUnitId, ventauId]);

  async function handleSend() {
    setSending(true);

    if (confirmedChange) {
      await fetch(
        `${
          window.location.href.includes("localhost")
            ? "http://localhost"
            : "https://app.intelimotor.com"
        }/api/ventau/offers/${ventauId}/${
          offer.selectedOffer.businessUnit.id
        }/cancel`,
        {
          cache: "no-store",
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );

      await fetch(
        `${
          window.location.href.includes("localhost")
            ? "http://localhost"
            : "https://app.intelimotor.com"
        }/api/ventau/offers/${ventauId}?utm_campaign=${
          window.localStorage.getItem("utm_campaign") || ""
        }&utm_source=${
          window.localStorage.getItem("utm_source") || ""
        }&utm_medium=${
          window.localStorage.getItem("utm_medium") || ""
        }&utm_content=${
          window.localStorage.getItem("utm_content") || ""
        }&utm_term=${window.localStorage.getItem("utm_term") || ""}`,
        { cache: "no-store" }
      );
    }

    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauId}`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offer: confirmedChange ? { ...offer, isSelected: false } : offer,
          date,
          timeSlot,
        }),
      }
    )
      .then(
        () => {},
        () => {}
      )
      .finally(() => {
        navigate(`/offer/${businessUnitId}/view`);
        setChangeOfferOpen(false);
        setConfirmedChange(false);
        setSending(false);
        setOffer(null);
      });
  }

  function handleCancel() {
    setSending(true);
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauId}/${businessUnitId}/cancel`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then(
        () => {},
        () => {}
      )
      .finally(() => {
        navigate(`/offers`);
        setSending(false);
      });
  }

  function handleAccept() {
    setSending(true);
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauId}/${businessUnitId}/accept`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then(
        () => {},
        () => {}
      )
      .finally(() => {
        setOffer({ ...offer, status: "confirmed" });
        setSending(false);
      });
  }

  if (!offer) {
    return (
      <Layout>
        <Box sx={{ textAlign: "center", py: 14 }}>
          <CircularProgress size={100} sx={{ mb: 2 }} />
          <Typography variant="subtitle2">Cargando...</Typography>
        </Box>
      </Layout>
    );
  }

  if (offer.isSelected && moment(offer.inspectionAt).isBefore(moment())) {
    return (
      <Layout>
        <Stack spacing={8} sx={{ py: 3 }}>
          <Box sx={{ pb: 8, display: "flex", justifyContent: "center" }}>
            <Box
              sx={(theme) => ({
                backgroundImage:
                  "linear-gradient(to right, black 0%, #434343 100%)",
                px: 3,
                py: 3,
                textAlign: "center",
                [theme.breakpoints.up("md")]: {
                  textAlign: "left",
                  px: 8,
                  py: 6,
                },
                mt: 6,
              })}
              maxWidth="800px"
              width="100%"
              borderRadius={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={2}>
                    <Typography variant="h4" color="white">
                      ¡Cuéntanos&nbsp;
                      <span className="gradient gradient--text">
                        cómo te fue
                      </span>
                      !
                    </Typography>
                    <Typography variant="body1" color="white">
                      {`En Ventau es nuestra prioridad que recibas el mejor servicio por parte de nuestros aliados. Queremos conocer cómo te fue en tu cita de inspección con ${offer.businessUnit.name}.`}
                    </Typography>

                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",

                        [theme.breakpoints.up("md")]: {
                          justifyContent: "flex-start",
                        },
                      })}
                    >
                      <Button
                        className="gradient"
                        variant="contained"
                        size="large"
                        onClick={() =>
                          (window.location.href = offer.sellerWonFeedbackUrl)
                        }
                        sx={{ mr: 1, mb: 1 }}
                      >
                        {offer.type === "purchase" && "¡Lo vendí!"}
                        {offer.type === "consignment" && "¡En consignación!"}
                      </Button>

                      {offer.type === "purchase" && (
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={() =>
                            (window.location.href =
                              offer.sellerNegotiationFeedbackUrl)
                          }
                          sx={{
                            mr: 1,
                            mb: 1,
                            color: "#fff",
                            borderColor: "#fff",
                            "&:hover": { borderColor: "#fff" },
                          }}
                        >
                          En negociación
                        </Button>
                      )}

                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() =>
                          (window.location.href = offer.sellerLostFeedbackUrl)
                        }
                        sx={{
                          mr: 1,
                          mb: 1,
                          color: "#fff",
                          borderColor: "#fff",
                          "&:hover": { borderColor: "#fff" },
                        }}
                      >
                        {offer.type === "purchase" && "No lo vendí"}
                        {offer.type === "consignment" &&
                          "No lo dejé a consignación"}
                      </Button>
                    </Box>
                  </Stack>
                </Grid>
                <Hidden mdDown>
                  <Grid item md={4}>
                    <Box
                      component="img"
                      src="https://pro.ventau.com/v.svg"
                      alt=""
                      sx={{
                        width: 100,
                        height: 100,
                        transform:
                          "scale(2.8) translateX(50px) translateY(25px)",
                        filter:
                          "drop-shadow(0 4px 3px #00000032) drop-shadow(0 2px 2px #0000000f)",
                      }}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Layout>
    );
  }

  if (offer.isSelected && action !== "reschedule") {
    return (
      <Layout>
        <Stack spacing={8} sx={{ py: 3 }}>
          <Box>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Revisa&nbsp;
              <span className="gradient gradient--text">los detalles</span>
              &nbsp;de tu cita
            </Typography>
          </Box>
          <Box>
            <Link
              component={RouterLink}
              to="/offers/change"
              sx={{
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
                color: "GrayText",
              }}
            >
              <ChevronLeft sx={{ marginTop: "-2px" }} />
              Ver más ofertas
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 4,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                  <Paper
                    variant="outlined"
                    sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  >
                    <List>
                      <ListItem sx={{ minHeight: "56px" }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <LocationOnOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary={offer.businessUnit.formattedAddress}
                        />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                  <Paper
                    variant="outlined"
                    sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  >
                    <List>
                      <ListItem sx={{ minHeight: "56px" }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <Event />
                        </ListItemIcon>
                        <ListItemText primary={offer.inspectionDate} />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                  <Paper
                    variant="outlined"
                    sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  >
                    <List>
                      <ListItem sx={{ minHeight: "56px" }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText primary={offer.inspectionTimeSlot} />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ pb: 8, display: "flex", justifyContent: "center" }}>
            <Box
              sx={(theme) => ({
                backgroundImage:
                  "linear-gradient(to right, black 0%, #434343 100%)",
                px: 3,
                py: 3,
                textAlign: "center",
                [theme.breakpoints.up("md")]: {
                  textAlign: "left",
                  px: 8,
                  py: 6,
                },
                mt: 6,
              })}
              maxWidth="800px"
              width="100%"
              borderRadius={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={2}>
                    {offer.status === "confirmed" && (
                      <>
                        <Typography variant="h4" color="white">
                          ¡Tu cita fué&nbsp;
                          <span className="gradient gradient--text">
                            confirmada
                          </span>
                          !
                        </Typography>
                        <Typography variant="body1" color="white">
                          Asiste a tu cita de inspección para concretar la venta
                          de tu auto.
                        </Typography>
                      </>
                    )}

                    {offer.status === "requested-by-seller" && (
                      <>
                        <Typography variant="h4" color="white">
                          ¡Tu solicitud fué&nbsp;
                          <div className="gradient gradient--text">
                            enviada con éxito!
                          </div>
                        </Typography>
                        <Typography variant="body1" color="white">
                          Tu reserva no está confirmada todavía. Recibirás una
                          respuesta de parte de la Agencia en las próximas 24
                          horas.
                        </Typography>
                      </>
                    )}

                    {offer.status === "requested-by-buyer" && (
                      <>
                        <Typography variant="h4" color="white">
                          ¡Revisa la&nbsp;
                          <div className="gradient gradient--text">
                            propuesta de fecha
                          </div>
                          {` de cita de inspección de ${offer.businessUnit.name}!`}
                        </Typography>
                        <Typography variant="body1" color="white">
                          {`La cita propuesta es ${offer.inspectionDate.toLowerCase()} a las ${
                            offer.inspectionTimeSlot
                          }`}
                        </Typography>
                      </>
                    )}

                    {offer.status !== "requested-by-buyer" && (
                      <>
                        <div>
                          <Button
                            className="gradient"
                            variant="contained"
                            size="large"
                            component={RouterLink}
                            to={`/offer/${businessUnitId}/reschedule`}
                          >
                            Re-agendar
                          </Button>
                        </div>
                        <Typography color="white" variant="body2">
                          Si lo requieres, puedes{" "}
                          <Link
                            component={RouterLink}
                            to={`/offer/${businessUnitId}/cancel`}
                            sx={{ cursor: "pointer" }}
                          >
                            cancelar tu cita.
                          </Link>
                        </Typography>
                      </>
                    )}

                    {offer.status === "requested-by-buyer" && (
                      <>
                        <div>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={handleAccept}
                            {...(sending
                              ? { disabled: true }
                              : { className: "gradient" })}
                          >
                            {sending && (
                              <CircularProgress size={24} sx={{ mr: 1 }} />
                            )}
                            Confirmar cita
                          </Button>
                        </div>
                        <Typography color="white" variant="body2">
                          Si lo requieres, puedes{" "}
                          <Link
                            component={RouterLink}
                            to={`/offer/${businessUnitId}/reschedule`}
                            sx={{ cursor: "pointer" }}
                          >
                            re-agendar
                          </Link>
                          {" o "}
                          <Link
                            component={RouterLink}
                            to={`/offer/${businessUnitId}/cancel`}
                            sx={{ cursor: "pointer" }}
                          >
                            cancelar
                          </Link>{" "}
                          tu cita
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Hidden mdDown>
                  <Grid item md={4}>
                    <Box
                      component="img"
                      src="https://pro.ventau.com/v.svg"
                      alt=""
                      sx={{
                        width: 100,
                        height: 100,
                        transform:
                          "scale(2.8) translateX(50px) translateY(25px)",
                        filter:
                          "drop-shadow(0 4px 3px #00000032) drop-shadow(0 2px 2px #0000000f)",
                      }}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Box>
        </Stack>

        <Dialog
          open={action === "cancel"}
          fullWidth
          maxWidth="sm"
          onClose={() => navigate(`/offer/${businessUnitId}/view`)}
        >
          <DialogTitle>
            ¿Estás seguro de que quieres cancelar tu cita de inspección?
          </DialogTitle>
          <DialogContent>
            No te preocupes, una vez que canceles tu cita podrás revisar
            nuévamente las otras ofertas que las Agencias de la red de Ventau
            tienen por tu auto y agendar otra cita.
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate(`/offer/${businessUnitId}/view`)}
              disabled={sending}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancel}
              disabled={sending}
            >
              {sending && <CircularProgress size={24} sx={{ mr: 1 }} />}
              Cancelar cita
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    );
  }

  return (
    <Layout>
      <Stack spacing={4} sx={{ py: 3 }}>
        <Box>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            {action === "reschedule" ? "Reagenda tu " : "Agenda tu "}
            <span className="gradient gradient--text">inspección</span>
          </Typography>
        </Box>

        <Box
          sx={{
            maxWidth: 1200,
            alignSelf: "center",
            width: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          {action !== "reschedule" ? (
            <Link
              component={RouterLink}
              to={offer.selectedOffer ? "/offers/change" : "/offers"}
              sx={{
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
                color: "GrayText",
              }}
            >
              <ChevronLeft sx={{ marginTop: "-2px" }} />
              Ver más ofertas
            </Link>
          ) : (
            <Link
              component={RouterLink}
              to={`/offer/${businessUnitId}/view`}
              sx={{
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
                color: "GrayText",
              }}
            >
              <ChevronLeft sx={{ marginTop: "-2px" }} />
              Cancelar
            </Link>
          )}
        </Box>

        <Box sx={{ maxWidth: 1200, alignSelf: "center", width: "100%" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                1. Valida los datos de tu auto
              </Typography>
              <Stack spacing={2}>
                <Card>
                  <CardHeader
                    avatar={<Avatar src={offer.seller.brandLogo} />}
                    title={[
                      offer.seller.brand,
                      offer.seller.model,
                      offer.seller.year,
                      offer.seller.trim,
                    ].join(" ")}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Stack spacing={2}>
                      {!!offer.seller.modelPicture && (
                        <Box sx={{ textAlign: "center" }}>
                          <Box
                            component="img"
                            src={offer.seller.modelPicture}
                            alt={`Representación visual de un ${[
                              offer.seller.brand,
                              offer.seller.model,
                            ].join(" ")}`}
                            sx={{
                              width: 200,
                              height: 150,
                              objectFit: "contain",
                              maxWidth: "100%",
                            }}
                          />
                        </Box>
                      )}
                      <div>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              Marca
                            </Typography>
                            <Typography>{offer.seller.brand}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              Modelo
                            </Typography>
                            <Typography>{offer.seller.model}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              Año
                            </Typography>
                            <Typography>{offer.seller.year}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <div>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              Versión
                            </Typography>
                            <Typography>{offer.seller.trim}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              Kilometraje
                            </Typography>
                            <Typography>
                              {offer.seller.kms
                                ? `${toNumber(offer.seller.kms)}km`
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
                <Typography variant="body2" color="GrayText">
                  ¿Los datos son incorrectos? Si necesitas hacer cambios,
                  selecciona{" "}
                  <Link href="https://ventau.com/#empezar">Modificar</Link> para
                  volver a ingresar tus datos.
                </Typography>
                {!!offer.seller.modelPicture && (
                  <Typography variant="caption" color="GrayText">
                    * La imagen utilizada en esta ficha es solo una referencia
                    ilustrativa. No es una representación visual exacta de la
                    unidad.
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                2. Revisa tu oferta
              </Typography>
              <Offer
                variant="featured"
                feature={"Agenda tu cita de inspección"}
                featureIcon={() => <DateRange />}
                data={offer}
                noActions
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                3. Selecciona una fecha y hora
              </Typography>
              <Stack spacing={4}>
                <DateCalendar
                  minDate={moment().add(2, "days")}
                  maxDate={moment().add(1, "month").endOf("month")}
                  value={date ? moment(date) : null}
                  onChange={(newDate) => {
                    setDate(newDate.format("YYYY-MM-DD"));
                    setTimeSlot(null);
                  }}
                  sx={{
                    mx: 0,
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "10px",
                    background: "white",
                    width: "100%",
                  }}
                  showDaysOutsideCurrentMonth
                  shouldDisableDate={(date) =>
                    !offer.businessUnit.schedule.some(
                      (period) => period.weekdays[0] === date.day()
                    )
                  }
                  disableHighlightToday
                />
                <Box sx={{ textAlign: "center" }}>
                  {!availableTimeSlots && date && (
                    <CircularProgress size={48} />
                  )}

                  {availableTimeSlots && availableTimeSlots.length === 0 && (
                    <Typography variant="caption" color="textSecondary">
                      No hay horarios disponibles en la fecha seleccionada.
                    </Typography>
                  )}

                  {availableTimeSlots &&
                    availableTimeSlots.map((item) => (
                      <Chip
                        key={item}
                        label={item}
                        sx={{
                          mx: 0.5,
                          mb: 1,
                          ...(item !== timeSlot
                            ? { backgroundColor: "#fff" }
                            : {}),
                        }}
                        variant="outlined"
                        onClick={() => setTimeSlot(item)}
                        {...(item === timeSlot
                          ? {
                              variant: "filled",
                              color: "primary",
                              className: "gradient",
                            }
                          : {})}
                      />
                    ))}
                </Box>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmed}
                        color="secondary"
                        onChange={(e) => setConfirmed(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {offer.type === "consignment"
                          ? "Confirmo que los datos proporcionados de mi auto son precisos."
                          : "Confirmo que los datos proporcionados de mi auto son precisos. Estoy de acuerdo en que el monto final que la agencia oferte por mi auto está sujeto a su inspección y a la veracidad de los datos que he proporcionado."}
                      </Typography>
                    }
                    sx={{ alignItems: "flex-start" }}
                  />
                </FormGroup>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="large"
                    variant="contained"
                    {...(!date || !timeSlot || sending || !confirmed
                      ? { disabled: true }
                      : { className: "gradient" })}
                    onClick={() => {
                      if (offer.selectedOffer && action !== "reschedule") {
                        setConfirmedChange(false);
                        setChangeOfferOpen(true);
                        return;
                      }

                      handleSend();
                    }}
                  >
                    {sending ? (
                      <CircularProgress size={24} sx={{ mr: 1 }} />
                    ) : (
                      <ScheduleSend sx={{ mr: 1 }} />
                    )}
                    Enviar solicitud de cita
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Dialog open={changeOfferOpen} maxWidth="md" fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>
          <CarCrashOutlined sx={{ fontSize: 64 }} />
          <Typography variant="h4">
            ¿<span className="gradient gradient--text">Reemplazar</span> cita de
            inspección?
          </Typography>
        </DialogTitle>
        <Divider />
        {changeOfferOpen && (
          <DialogContent>
            <Stack spacing={2}>
              <Typography sx={{ textAlign: "center" }}>
                <b>{offer.seller.firstName}</b>, parece que ya tienes programada
                una cita de inspección.
                <br />
                ¿Deseas{" "}
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                >
                  cancelar y reprogramar tu cita
                </Typography>{" "}
                bajo esta nueva oferta de compra?
              </Typography>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className="gradient" variant="rounded">
                            <CalendarToday />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: "bold" }}>
                              Cita de inspección
                            </Typography>
                          }
                          secondary={
                            <Typography variant="caption">
                              Confirmada con{" "}
                              <Typography
                                color="primary"
                                component="span"
                                sx={{ fontWeight: "bold" }}
                                variant="caption"
                              >
                                {offer.selectedOffer.businessUnit.name}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemIcon>
                          <InsertInvitation />
                        </ListItemIcon>
                        <ListItemText
                          primary={offer.selectedOffer.inspectionDate}
                          secondary="Fecha"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <AccessTime />
                        </ListItemIcon>
                        <ListItemText
                          primary={offer.selectedOffer.inspectionTimeSlot}
                          secondary="Hora"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <PlaceOutlined />
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            offer.selectedOffer.businessUnit.formattedAddress
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{ textAlign: "center", alignSelf: "center" }}
                  >
                    <CompareArrows sx={{ fontSize: 96 }} color="action" />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className="gradient" variant="rounded">
                            <CalendarToday />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: "bold" }}>
                              Nueva cita de inspección
                            </Typography>
                          }
                          secondary={
                            <Typography variant="caption">
                              Por confirmar con{" "}
                              <Typography
                                color="primary"
                                component="span"
                                sx={{ fontWeight: "bold" }}
                                variant="caption"
                              >
                                {offer.businessUnit.name}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemIcon>
                          <InsertInvitation />
                        </ListItemIcon>
                        <ListItemText
                          primary={(() => {
                            const momentDate = moment(date);
                            return `El ${momentDate.format(
                              "dddd"
                            )}, ${momentDate.format(
                              "D"
                            )} de ${momentDate.format("MMMM")}`;
                          })()}
                          secondary="Fecha"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <AccessTime />
                        </ListItemIcon>
                        <ListItemText primary={timeSlot} secondary="Hora" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <PlaceOutlined />
                        </ListItemIcon>
                        <ListItemText
                          secondary={offer.businessUnit.formattedAddress}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Divider />
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pt: 2,
                [theme.breakpoints.up("md")]: {
                  flexDirection: "row",
                },
              })}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmedChange}
                      color="secondary"
                      onChange={(e) => setConfirmedChange(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      Confirmo que estoy cancelando mi cita previa y solicitando
                      una nueva cita de inspección sujeta a disponibilidad.
                      Estoy de acuerdo en que el monto final que la agencia
                      oferte por mi auto está sujeto a su inspección y a la
                      veracidad de los datos que he proporcionado.
                    </Typography>
                  }
                  sx={{ alignItems: "flex-start" }}
                />
              </FormGroup>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pt: 2,
                  [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                  },
                })}
              >
                <Button
                  size="large"
                  variant="contained"
                  {...(!date ||
                  !timeSlot ||
                  sending ||
                  !confirmed ||
                  !confirmedChange
                    ? { disabled: true }
                    : { className: "gradient" })}
                  onClick={handleSend}
                  sx={(theme) => ({
                    whiteSpace: "nowrap",
                    mt: 2,
                    [theme.breakpoints.up("md")]: { mt: 0 },
                  })}
                >
                  {sending ? (
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                  ) : (
                    <ScheduleSend sx={{ mr: 1 }} />
                  )}
                  Enviar solicitud de cita
                </Button>

                <Button
                  onClick={() => setChangeOfferOpen(false)}
                  sx={(theme) => ({
                    mt: 2,
                    [theme.breakpoints.up("md")]: { mt: 0, ml: 1 },
                  })}
                  color="primary"
                  disabled={sending}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </Layout>
  );
}

export default Inspection;
